import { createAction } from '@reduxjs/toolkit';

import { PaymentMethods, Providers } from '../../config/providers';
import { ProvidersState } from './state';
import { PostService } from './types';
import { Delivers, Pricing, ProvidersMap } from '../../client';

const prefix = (msg: string): string => `[providers] ${msg}`;

export const fullReset = createAction(prefix('full reset'));

export const submitForm = createAction(prefix('submit form'));

export const setMode = createAction<Pick<ProvidersState, 'mode'>>(prefix('set mode'));
export const setDocs = createAction<{ noDocs: boolean }>(prefix('set docs'));
export const setParcelTemplate = createAction<{ parcelTemplate: string }>(prefix('set parcel template'));

export const setCountryAct = createAction<Partial<{ from: string; to: string }>>(prefix('set country'));

export const setInboundListAct = createAction<Partial<{ inboundList?: Providers[] }>>(prefix('set inbound country'));
export const fetchProvidersPairAct = createAction(prefix('fetch providers pair'));
export const setProvidersPairAct = createAction<Partial<{ providersPair: ProvidersMap | null }>>(
  prefix('set providers pair')
);

export const setPrice = createAction<{
  price: string;
  price_eur: string;
  discount_price: string | null;
  discount: string | null;
}>(prefix('set parcel price'));
export const setDelivers = createAction<{ delivers: Delivers[] }>(prefix('set delivers list'));
export const setDeliveryDiff = createAction<{ courier: Pricing; department: Pricing }>(
  prefix('set delivery pricing diff')
);

// filters
export const setWeightAct = createAction<{ weight: number }>(prefix('set parcel weight'));
export const setWidthAct = createAction<{ width: number }>(prefix('set parcel width'));
export const setHeightAct = createAction<{ height: number }>(prefix('set parcel height'));
export const setLengthAct = createAction<{ length: number }>(prefix('set parcel length'));

// booking flow
export const bookService = createAction<{ service: PostService | string }>(prefix('book post service'));
export const payment = createAction<{
  provider: Providers;
  orderID: string;
  linkID?: string;
  paymentType: PaymentMethods;
  shipment: number;
}>(prefix('send shipments payment'));
export const createPiraeusTicket = createAction<{
  provider?: Providers;
  shipment?: number;
  linkID?: string;
}>(prefix('create piraeus ticket'));

export const processPiraeusTicket = createAction(prefix('process piraeus ticket'));

// api errors
export const setErrors = createAction<{ errors: Record<string, string> }>(prefix('set api errors'));
export const resetError = createAction<{ key: string }>(prefix('reset api errors'));
export const setAlerts = createAction<{ errors: Record<string, string> }>(prefix('set alert errors'));
export const resetAlert = createAction<{ key: string }>(prefix('reset alert error'));
