import React from 'react';
import styled from 'styled-components';

import { useTranslation } from '../../../i18n';

import { Layout, Card } from '../../element';
import { Calculator } from '../calculator';
import { GradientTitle, MainTitle } from '../index';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 600px;
  background: url('/static/pages/home/delivery/bg.png') 50%;

  & > .wrapper > .el-row {
    margin: 20px 0;
  }
`;

export const DeliveryBlock: React.FC = () => {
  const [t] = useTranslation('home');

  return (
    <>
      <Layout.Row className="wrapper mb-30">
        <Layout.Col span={24} sm={24}>
          <MainTitle>
            <span>{t('home.delivery.title')}</span>
            <br />
            <GradientTitle>{t('home.delivery.gradient')}</GradientTitle>
          </MainTitle>
        </Layout.Col>
      </Layout.Row>

      <Wrapper>
        <div className="wrapper">
          <Layout.Row gutter={20} style={{ margin: '20px 0' }}>
            <Layout.Col span={24} sm={24} md={10} lg={10} className="mb-20">
              <Calculator />
            </Layout.Col>
            <Layout.Col span={24} sm={24} md={14} lg={14} className="mb-20">
              <Card className="is-hover-shadow">
                <video
                  muted
                  controls
                  loop
                  poster="/static/pages/home/delivery/inpost-ukrposhta.png"
                  preload="none"
                  style={{
                    width: '100%',
                  }}
                >
                  <source src="/static/pages/home/delivery/inpost-ukrposhta.mp4" type="video/mp4" />
                </video>
              </Card>
            </Layout.Col>
          </Layout.Row>
        </div>
      </Wrapper>
    </>
  );
};
