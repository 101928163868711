import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as providerActs from '../../../entities/providers';
import * as selectors from '../../../entities/providers/selectors';

import { Slider } from '../../element';
import { FormLabel } from '../../page';

import { useTranslation } from '../../../i18n';

interface Props {
  label?: string;
  showTooltip?: boolean;
}

export const ParcelWeightSlider: React.FC<Props> = ({ label, showTooltip = false }) => {
  const [t] = useTranslation('common');

  const max = useSelector(selectors.getMaxParcelWeight);
  const weight = useSelector(selectors.getParcelWeight);

  const dispatch = useDispatch();

  const handleChange = useCallback((value: number) => {
    dispatch(providerActs.setWeightAct({ weight: value }));
  }, []);

  return (
    <>
      {label && (
        <FormLabel className="FormLabel">
          {label} {weight.toFixed(3)} {t('units.kg')}
        </FormLabel>
      )}
      <Slider min={1} step={0.1} showTooltip={showTooltip} max={max} value={weight} onChange={handleChange} />
    </>
  );
};
