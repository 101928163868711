import styled from 'styled-components';

export const MainTitle = styled.h2`
  display: block;
  margin: 0;
  padding: 0;
  font-size: 48px;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 20px;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

export const GradientTitle = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;
