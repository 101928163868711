import React from 'react';
import classNames from 'classnames';

import { useTranslation } from '../../../i18n';

import { Layout } from '../../element';
import { Image } from '../../image';

import { GradientTitle, MainTitle } from '../index';

export const ServiceBlock: React.FC = () => {
  const [t] = useTranslation('home');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const articles = (t('home.service.articles', { returnObjects: true, defaultValue: [] }) as any[]).map(
    (card, idx) => ({
      image: `/static/pages/home/service/${idx + 1}.png`,
      title: card.title,
      text: card.text,
    })
  );

  return (
    <>
      <Layout.Row className="wrapper">
        <Layout.Col span={24} sm={24}>
          <div className="mb-30">
            <MainTitle>
              {t('home.service.title')} <GradientTitle>SMART Post</GradientTitle>
            </MainTitle>
          </div>

          {articles.map((article, idx) => (
            <Layout.Row
              key={idx.toString()}
              gutter={20}
              type="flex"
              className={classNames('mb-25', 'flex-wrap', {
                'fd-row-reverse': idx % 2,
              })}
            >
              <Layout.Col span={24} sm={12}>
                <Image src={article.image} alt="" />
              </Layout.Col>

              <Layout.Col span={24} sm={12}>
                <h3>{article.title}</h3>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: article.text }} />
              </Layout.Col>
            </Layout.Row>
          ))}
        </Layout.Col>
      </Layout.Row>
    </>
  );
};
