import React from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';

import styled from 'styled-components';

import { useTranslation } from '../../../i18n';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AwesomeSwiper: any = dynamic(() => import('react-awesome-swiper'), {
  ssr: false,
});

const config = {
  loop: true,
  effect: 'fade',
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
};

const Image = styled.img`
  width: 100%;
`;

export const HomeSlider: React.FC = () => {
  const [, i18n] = useTranslation();
  const links = Array.from({ length: 5 }, (_, idx) => `/static/pages/home/slider/${i18n.language}${idx + 1}.png`);

  return (
    <>
      <Head>
        <link rel="stylesheet" href="/static/css/swiper.css" />
      </Head>
      <AwesomeSwiper config={config} className="your-classname">
        <div className="swiper-wrapper">
          {links.map((link, idx) => (
            <div key={idx.toString()} className="swiper-slide">
              <Image src={link} alt="" />
            </div>
          ))}
        </div>
        <div className="swiper-pagination" />
      </AwesomeSwiper>
    </>
  );
};
