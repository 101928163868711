import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { Router, useTranslation } from '../../../i18n';

import { SweetModal } from '../../ui/modal/sweet-modal';

export const HomeSuccessModal = () => {
  const router = useRouter();

  const [t] = useTranslation('home');
  const [isOpen, setOpen] = useState(false);

  const {
    query: { successfulPayment },
  } = router;

  const enabled = successfulPayment === 'true';

  useEffect(() => {
    if (enabled) {
      setTimeout(() => setOpen(true), 500);
    }
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);

    // remove `successfulPayment` param from URL
    Router.replace('/').then();
  }, []);

  return (
    <>
      {enabled && (
        <SweetModal icon open={isOpen} onClose={handleClose}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: t('home.modal.payment') }} />
        </SweetModal>
      )}
    </>
  );
};
