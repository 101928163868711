import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dispatchProviders } from '../../../config/countries';

import * as acts from '../../../entities/providers/actions';
import * as selectors from '../../../entities/providers/selectors';

import { useTranslation } from '../../../i18n';
import { Layout, Card } from '../../element';
import { FormLabel } from '../../page';
import { SendParcel } from '../../anchor/send-parcel';

import { GradientTitle } from '../index';
import { ProviderSelect } from '../../providers/provider-select';
import { ParcelWeightSlider } from '../../providers/parcel-weight-slider';

import { priceCurrencyToSign } from '../../../helpers/currency';
import { Providers } from '../../../config/providers';

export const Calculator: React.FC = () => {
  const [t] = useTranslation('home');
  const price = useSelector(selectors.getParcelPrice);

  const dispatchCountry = useSelector(selectors.getDispatchCountry);
  const inboundCountry = useSelector(selectors.getInboundCountry);

  const inboundCountries: Providers[] = useSelector(selectors.getInboundProviders);

  const dispatch = useDispatch();

  // trigger api call
  useEffect(() => {
    dispatch(acts.setMode({ mode: 'calculator' }));
  }, []);

  return (
    <Card className="overflow-visible">
      <Layout.Row gutter={20}>
        <Layout.Col span={24} xs={24} sm={12} md={12} lg={12} className="mb-30">
          <ProviderSelect
            label={t('home:home.delivery.calculator.from')}
            providers={dispatchProviders}
            country={dispatchCountry.code}
            onChange={(from) => dispatch(acts.setCountryAct({ from }))}
          />
        </Layout.Col>
        <Layout.Col span={24} xs={24} sm={12} md={12} lg={12} className="mb-30">
          <ProviderSelect
            label={t('home:home.delivery.calculator.to')}
            providers={inboundCountries}
            country={inboundCountry.code}
            onChange={(to) => dispatch(acts.setCountryAct({ to }))}
          />
        </Layout.Col>

        <Layout.Col span={24} style={{ marginTop: '25px' }} className="mb-30">
          <ParcelWeightSlider label={t('home.delivery.calculator.weightLabel')} />
        </Layout.Col>
        {price && (
          <Layout.Col span={24} className="mb-30">
            <FormLabel>
              {t('home.delivery.calculator.price')}:{' '}
              <GradientTitle>
                {t('home.delivery.calculator.priceFrom')} {priceCurrencyToSign(price)}
              </GradientTitle>
            </FormLabel>
            <span>
              <span style={{ color: 'red' }}>*</span> {t('home.delivery.calculator.fullPrice')}
            </span>
          </Layout.Col>
        )}
        <Layout.Col span={24}>
          <SendParcel withParams />
        </Layout.Col>
      </Layout.Row>
    </Card>
  );
};
