import React from 'react';
import styled from 'styled-components';

import { useTranslation } from '../../../i18n';

import { Layout } from '../../element';
import { GradientTitle, MainTitle } from '../index';

import { BarcodeForm } from '../../tracking-form/tracking-form.component';

const FormWrapper = styled.div`
  max-width: 780px;
  margin: 0 auto;
`;

export const TrackingBlock: React.FC = () => {
  const [t] = useTranslation('home');

  return (
    <>
      <Layout.Row className="wrapper">
        <Layout.Col span={24} sm={24}>
          <div className="mb-30">
            <MainTitle>
              <GradientTitle>SMART</GradientTitle> {t('home.tracking.title')}
            </MainTitle>
          </div>

          <FormWrapper>
            <BarcodeForm placeholder="DK123456789GR" btnLabel={t('home.tracking.submit')} plainBtn />
          </FormWrapper>
        </Layout.Col>
      </Layout.Row>
    </>
  );
};
