import React from 'react';
import styled from 'styled-components';

import { useTranslation } from '../../../i18n';

import { Layout } from '../../element';
import { GradientTitle, MainTitle } from '../index';

const CardImg = styled.div`
  text-align: center;

  & img {
    width: 40%;
  }
`;

const CardTitle = styled.div`
  margin: 20px 0;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
`;

const CardText = styled.div`
  margin-bottom: 20px;
  text-align: justify;
`;

export const WhyChooseBlock: React.FC = () => {
  const [t] = useTranslation('home');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cards = (t('home.why.cards', { returnObjects: true, defaultValue: [] }) as any[]).map((card, idx) => ({
    image: `/static/pages/home/why/${idx + 1}.png`,
    title: card.title,
    text: card.text,
  }));

  return (
    <>
      <Layout.Row className="wrapper">
        <Layout.Col span={24} sm={24}>
          <div className="mb-30">
            <MainTitle>
              {t('home.why.title')}
              <br />
              <GradientTitle>SMART Post</GradientTitle>
            </MainTitle>
          </div>

          <Layout.Row gutter={20}>
            {cards.map((card, idx) => (
              <Layout.Col key={idx.toString()} span={24} sm={8}>
                <CardImg>
                  <img src={card.image} alt="" />
                </CardImg>
                <CardTitle>{card.title}</CardTitle>
                <CardText>{card.text}</CardText>
              </Layout.Col>
            ))}
          </Layout.Row>
        </Layout.Col>
      </Layout.Row>
    </>
  );
};
