import { Decimal } from 'decimal.js';
import { deepFind } from './array';

export const equals = (a: string | number, b: string | number): boolean => new Decimal(a).eq(b);

export const difference = (...list: number[]): number =>
  list.reduce((acc, num) => new Decimal(num).minus(acc).toNumber(), 0);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sum<T = any>(list: T[], key: string): number {
  return list.reduce((acc: number, item: T): number => {
    const num = deepFind<string | number>(item, key);

    if (num) {
      return new Decimal(num).add(acc).toNumber();
    }

    return acc;
  }, 0);
}

export function simpleSum<T = string | number>(list: T[]): number {
  return list.reduce((acc: number, num: T): number => {
    if (num) {
      return new Decimal(num as never).add(acc).toNumber();
    }

    return acc;
  }, 0);
}
