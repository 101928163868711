import { difference } from './numbers';

const currencyMap = {
  UDS: '$',
  EUR: '€',
  GBP: '£',
  PLN: 'zł',
  UAH: '₴',
};

export type CURRENCY = keyof typeof currencyMap;

export const currencyToSign = (currency: CURRENCY): string => currencyMap[currency];

/**
 * Extract the numeric price from string like `10 EUR`
 */
export const extractPrice = (value: string): number => {
  const [price] = value.split(' ');

  return Number(price);
};

/**
 * Extract the numeric price from string like `10 EUR`
 */
export const extractCurrency = (value: string): CURRENCY => {
  const [, currency] = value.split(' ');

  return currency as never;
};

export const getPriceDifference = (normalPrice: string, discountedPrice: string): string => {
  const price = extractPrice(normalPrice);
  const discounted = extractPrice(discountedPrice);

  const diff = Math.abs(difference(price, discounted)).toFixed(2);

  const currency = extractCurrency(normalPrice);

  return `-${diff} ${currencyToSign(currency)}`;
};

export const priceCurrencyToSign = (price: string): string => {
  if (price) {
    const [amount, currency] = price.split(' ');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return `${Number(amount).toFixed(2)} ${currencyToSign(currency as any)}`;
  }

  return price;
};

export const extractPriceValue = (price: string): number => parseFloat(price.replace(',', '.'));
