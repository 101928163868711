import React from 'react';
import styled from 'styled-components';

import { AppLayout } from '../layouts/app.layout';
import { HomeSlider } from '../components/home/slider';
import { DeliveryBlock } from '../components/home/delivery';
import { TrackingBlock } from '../components/home/trackings';
import { ServiceBlock } from '../components/home/service';
import { WhyChooseBlock } from '../components/home/why-choose';
import { HomeSuccessModal } from '../components/home/success-modal';

const Divider = styled.div`
  margin-bottom: 97px;
`;

const HomePage = () => (
  <AppLayout>
    <Divider>
      <HomeSlider />
    </Divider>

    <Divider>
      <DeliveryBlock />
    </Divider>

    <Divider>
      <TrackingBlock />
    </Divider>

    <Divider>
      <ServiceBlock />
    </Divider>

    <Divider>
      <WhyChooseBlock />
    </Divider>

    <HomeSuccessModal />
  </AppLayout>
);

HomePage.getInitialProps = async () => ({
  namespacesRequired: ['common', 'home', 'tracking'],
});

export default HomePage;
