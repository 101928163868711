/* eslint-disable @typescript-eslint/no-explicit-any */
export function pick<T = object>(source: Record<string, T>) {
  return (...keys: string[]): Array<T & { code: string }> =>
    keys.map((key) => ({
      ...source[key],
      code: key,
    }));
}

export function deepFind<T = any>(obj: Record<string, any>, path: string): T | undefined {
  const paths = path.split('.');
  let current = obj;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return undefined;
    }

    current = current[paths[i]];
  }

  // @ts-ignore
  return current;
}
