import styled from 'styled-components';

export const OptionLabel = styled.div`
  display: flex;

  & div:first-of-type {
    flex: 1;

    margin-right: 5px;
  }

  & img {
    height: 30px;
  }
`;
