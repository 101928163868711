import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useTranslation } from '../../../i18n';

import { Select, SelectOption } from '../../element';

import { Providers } from '../../../config/providers';
import { Country, getCountryFlag, getProviderCountry } from '../../../config/countries';

import { OptionLabel } from './styled';
import { FormLabel } from '../../page';

const StyledSelect = styled(Select)`
  width: 100%;
`;

export interface ProviderSelectProps {
  label?: string;
  providers: Providers[];
  country?: string;
  onChange?: (code: string) => void;
}

export const ProviderSelect: React.FC<ProviderSelectProps> = ({ label, country, providers, onChange }) => {
  const [t] = useTranslation('common');

  const countriesList: Country[] = useMemo(() => providers.map((provider) => getProviderCountry(provider) as Country), [
    providers,
  ]);

  const [state, setState] = useState({
    code: String(country || countriesList[0]?.code),
  });

  const { code } = state;

  // update value on `country` prop changed
  useEffect(() => {
    if (country) {
      setState({ code: country });
    }
  }, [country]);

  const handleChange = useCallback((countryCode: string) => {
    setState((prevState) => ({
      ...prevState,
      code: countryCode,
    }));

    if (typeof onChange === 'function') {
      onChange(countryCode);
    }
  }, []);

  return (
    <div>
      {label && (
        <FormLabel>
          <img src={getCountryFlag(code)} alt={code} /> {label}
        </FormLabel>
      )}

      <StyledSelect value={code} onChange={handleChange}>
        {countriesList.map((item) => {
          const name = t(item.name);

          return (
            <SelectOption key={item.code} value={item.code} label={name}>
              <OptionLabel>
                <div>{name}</div>
                <div>
                  <img src={item.partnerLogo} alt={name} />
                </div>
              </OptionLabel>
            </SelectOption>
          );
        })}
      </StyledSelect>
    </div>
  );
};
