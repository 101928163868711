import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';

import { Router, useTranslation } from '../../i18n';
import { Button, Form, Input, Layout } from '../element';
import { trackingFormRules } from './rules';

export interface BarcodeFormProps {
  trackingCode?: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit?: (arg: { barcode: string }) => any;
  btnLabel?: string;
  plainBtn?: boolean;
}

export const BarcodeForm: React.FC<BarcodeFormProps> = ({
  trackingCode = '',
  btnLabel = '',
  placeholder,
  onSubmit,
  plainBtn,
}) => {
  const [t] = useTranslation(['tracking', 'common']);
  const [model, setModel] = useState({
    trackingCode,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formEl = useRef<any>();

  // update on props change
  useEffect(() => {
    setModel((prevState) => ({
      ...prevState,
      trackingCode,
    }));
  }, [trackingCode]);

  const handleSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();

      if (formEl && formEl.current) {
        formEl.current.validate((valid: boolean) => {
          if (valid) {
            const { trackingCode: barcode } = model;

            // dispatch action
            if (onSubmit) {
              onSubmit({
                barcode,
              });
            }

            // redirect to tracking page
            Router.push(`/tracking?tracking_number=${barcode}`).then();
          }
        });
      }
    },
    [model]
  );

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string) => (value: any) => {
      setModel((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
    []
  );

  const formProps = {
    onSubmit: handleSubmit,
  };

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <Form ref={formEl as any} model={model} rules={trackingFormRules(t)} {...formProps}>
        <Layout.Row gutter={20} type="flex" justify="center" className="mb-20">
          <Layout.Col span={18} md={10} lg={10}>
            <Form.Item prop="trackingCode">
              <Input
                value={model.trackingCode}
                placeholder={placeholder || t('tracking.placeholder')}
                prepend={<i className="el-icon-location-outline" />}
                onChange={handleChange('trackingCode')}
              />
            </Form.Item>
          </Layout.Col>

          <Layout.Col span={6} sm={6} md={4} lg={4}>
            <Form.Item>
              <Button type="primary" plain={!!plainBtn} nativeType="submit">
                <span className="hidden-sm-and-down">{btnLabel || t('tracking.btn')}</span>
                <i className="hidden-md-and-up el-icon-search" />
              </Button>
            </Form.Item>
          </Layout.Col>
        </Layout.Row>
      </Form>
    </>
  );
};
